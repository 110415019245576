import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import constants from './actionConstants';
import update from 'react-addons-update';
import { toast } from "react-toastify";
const _DEV = 'localhost';
let URL_PATH = '/api/graphql';
if (window.location.hostname === _DEV) {
  URL_PATH = `http://localhost:4000/api/graphql`;
}
const {
  CREATE_TRANSFER,
  GET_ACCOUNT_NAME,
  UPDATE_TRANSFER_STATUS,
  RETRY_TRANSFER,
  LOADING_STATEMENT,
  STATEMENT_AVAILABLE,
  ACCOUNT_STATEMENT
} = constants;



export const createTransfer = payload => {
  // console.log(payload);
  const client = new ApolloClient({
    uri: `${URL_PATH}`,
  });
  return async dispatch => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            createTransfer (
              sender_acct_no: "${payload.senderAccountNo}",
              sender_acct_name: "${payload.senderAccountName}"
              beneficiary_acct_no: "${payload.beneficiaryAccountNo}"
              beneficiary_acct_name: "${payload.beneficiaryAccountName}"
              beneficiary_org_id: "${payload.beneficiaryOrgId}"
              beneficiary_org_name: "${payload.beneficiaryBankName}"
              amount_wth_fees: "${payload.amount}"
              order_id: "${payload.orderId}"
              remarks: "${payload.remarks}"
              token: "${payload.token}"
              user_id: "${payload.userId}"
              phone_id: "${payload.phoneId}"
              org_id: "${payload.orgId}"
              initiator_id: "${payload.initiatorID}"
              initiator_name: "${payload.initiatorName}"
            ) {
              status
              message
              error
            }
          }
        `
      });

      dispatch({
        type: CREATE_TRANSFER,
        payload: response.data.createTransfer
      });
      return response.data.createTransfer;
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const updateTransferStatus = payload => {
  // console.log(payload);
  const client = new ApolloClient({
    uri: `${URL_PATH}`
  });
  return async dispatch => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            updateTransferStatus (
              token: "${payload.token}",
              user_id: "${payload.userId}"
              phone_id: "${payload.phoneId}"
              status: "${payload.status}"
              transfer_id: "${payload.id}"
              org_id: "${payload.orgId}"
            ) {
              status
              message
              error
            }
          }
        `
      });

      dispatch({
        type: UPDATE_TRANSFER_STATUS,
        payload: response.data.updateTransferStatus
      });
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const updateTransfersStatus = payload => {
  // console.log(JSON.stringify(payload.ids));
  const client = new ApolloClient({
    uri: `${URL_PATH}`
  });
  return async dispatch => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            updateTransfersStatus (
              token: "${payload.token}",
              user_id: "${payload.userId}"
              phone_id: "${payload.phoneId}"
              status: "${payload.status}"
              transfer_ids: [${payload.ids}]
              org_id: "${payload.orgId}"
              authorizer_id: "${payload.authorizerID}"
              authorizer_name: "${payload.authorizerName}"
            ) {
              status
              message
              error
            }
          }
        `
      });

      dispatch({
        type: UPDATE_TRANSFER_STATUS,
        payload: response.data.updateTransfersStatus
      });
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const getAccountName = payload => {
  const client = new ApolloClient({
    uri: `${URL_PATH}`
  });
  return async dispatch => {
    try {
      const response = await client.query({
        query: gql`
          query {
            getAccountName (
              acct_number: "${payload.acct_number}"
              bank_code: "${payload.bank_code}"
              dashboard: ${payload.dashboard}
              origin: ${payload.origin}
            ) {
              acct_name
              bank_name
              balance
            }
          }
        `
      });

      // response => {
      dispatch({
        type: GET_ACCOUNT_NAME,
        payload: response.data.getAccountName
      });
    } catch (err) {
      console.log(err);
      // dispatch({
      //   type: GET_ACCOUNT_NAME,
      //   payload: err
      // });
    }
  };
};

export const retryTransfer = payload => {
  const client = new ApolloClient({
    uri: `${URL_PATH}`
  });
  return async dispatch => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            retryTransfer (
              org_id: "${payload.orgId}",
              transfer_id: "${payload.transferId}"
            ) {
              status
              message
              error
            }
          }
        `
      });

      dispatch({
        type: RETRY_TRANSFER,
        payload: response.data.retryTransfer
      });
      return response.data.retryTransfer;
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const getAllTransfers = payload => {
 const dates = new Date();
let timeString = dates.toISOString().substr(0, 19);
timeString = timeString.replace(/:/g,"");
timeString = timeString.replace(/-/g,"");
  let queryConcat = '';
   queryConcat += `, timeString: "${timeString}" `;
  if (payload.status !== '' && payload.status !== undefined) {
    queryConcat += `, status: "${payload.status}" `;
  }
  if (payload.limit !== '' && payload.limit !== undefined) {
    queryConcat += `, limit: ${payload.limit} `;
  }
  if (payload.offset !== '' && payload.offset !== undefined) {
    queryConcat += `, offset: ${payload.offset} `;
  }
  if (payload.dateFilter !== '' && payload.dateFilter !== undefined) {
    queryConcat += `, date_filter: "${payload.dateFilter}" `;
  }
  if (payload.startDate !== '' && payload.startDate !== undefined) {
    queryConcat += `, start_date: "${payload.startDate}" `;
  }
  if (payload.endDate !== '' && payload.endDate !== undefined) {
    queryConcat += `, end_date: "${payload.endDate}" `;
  }
  if (payload.specificDate !== '' && payload.specificDate !== undefined) {
    queryConcat += `, specific_date: "${payload.specificDate}" `;
  }
  if (payload.queryFilter !== '' && payload.queryFilter !== undefined) {
    queryConcat += `, query_filter: "${payload.queryFilter}" `;
  }

  const client = new ApolloClient({
    uri: `${URL_PATH}`
  });
  return async dispatch => {
    try {
      const response = await client.query({
        query: gql`
          query {
            getAllTransfers(org_id: "${payload.orgId}" ${queryConcat}) {
              status
              data{
                id
                sender_acct_no
                sender_acct_name
                beneficiary_acct_no
                beneficiary_acct_name
                beneficiary_org_name
                beneficiary_org_id
                amount_wth_fees
                initiator_id
                initiator_name
                authorizer_id
                authorizer_name
                status
                remarks
                retries
                order_id
                updated_at
                created_at
                bank_message
                status_code
              }
              pagination{
                offset
                limit
                total_count
              }
              error
              message
            }
          }
        `
      });
      // console.log(response.data.getAllTransfers);
      return response.data.getAllTransfers;
    } catch (err) {
      throw new Error(err);
      // dispatch({
      //   type: GET_ACCOUNT_NAME,
      //   payload: err
      // });
    }
  };
};

export const getUserIdAndPhoneId = payload => {
  const client = new ApolloClient({
    uri: `${URL_PATH}`
  });
  return async dispatch => {
    try {
      const response = await client.query({
        query: gql`
          query {
            getUserIdAndPhoneId (
              phone_number: "${payload.phone}"
            ) {
              user_id
              phone_id
            }
          }
        `
      });
      return response.data.getUserIdAndPhoneId;
    } catch (err) {
      throw new Error(err);
      // dispatch({
      //   type: GET_ACCOUNT_NAME,
      //   payload: err
      // });
    }
  };
};

export const getAccountStatement = payload => {
  const client = new ApolloClient({
    uri: `${URL_PATH}`
  });
  return async dispatch => {
    try {
      dispatch({
        type: STATEMENT_AVAILABLE,
        payload: false
      });
      dispatch({
        type: LOADING_STATEMENT,
        payload: true
      });
      const response = await client.query({
        query: gql`
          query {
            accountStatement (
              acct_number: "${payload.acct_number}"
              start_date: "${payload.start_date}"
              end_date: "${payload.end_date}"
              service_code: "${payload.service_code}"
              account_type: "${payload.account_type}"
              nuban: ${payload.nuban}
              org_id: "${payload.org_id}"
            ) {
              statements {
                date
                naration
                transactionType
                transactionRef
                amount
                balance
              }
              balance_record {
                initialBalance
                finalBalance
                totalCredit
                totalDebit
              }
            }
          }
        `
      });

      console.log(response.data.accountStatement)
      // response => {
      dispatch({
        type: ACCOUNT_STATEMENT,
        payload: response.data.accountStatement
      });
      dispatch({
        type: LOADING_STATEMENT,
        payload: false
      });
      dispatch({
        type: STATEMENT_AVAILABLE,
        payload: true
      });
      toast.success("Account statement generated successfully");
    } catch (err) {
      console.log(err);
      dispatch({
        type: STATEMENT_AVAILABLE,
        payload: false
      });
      dispatch({
        type: LOADING_STATEMENT,
        payload: false
      });
      toast.error('Failed to generate account statement')
    }
  };
};


const getTransferMsg = (state, action) => {
  return update(state, {
    serverResponse: {
      $set: action.payload
    }
  });
};

const retryTrf = (state, action) => {
  return update(state, {
    retryTrfResponse: {
      $set: action.payload
    }
  });
};

const getAcctName = (state, action) => {
  return update(state, {
    accountName: {
      $set: action.payload.acct_name
    },
    accountBalance: {
      $set: action.payload.balance
    },
    bankName: {
      $set: action.payload.bank_name
    }
  });
};
const updateTrfStatus = (state, action) => {
  return update(state, {
    updateTransferStatusResponse: {
      $set: action.payload
    }
  });
};

const setStatementLoading = (state, action) => {
  return update(state, {
    statementLoading: {
      $set: action.payload
    },
  });
};

const setAvailalable = (state, action) => {
  return update(state, {
    statementAvailable: {
      $set: action.payload
    },
  });
};

const getAcctStatementData = (state, action) => {
  console.log({statement:state,  action})
  return update(state, {
    statements: {
      $set: action.payload
    },
  });
};

const ACTION_HANDLERS = {
  CREATE_TRANSFER: getTransferMsg,
  GET_ACCOUNT_NAME: getAcctName,
  UPDATE_TRANSFER_STATUS: updateTrfStatus,
  RETRY_TRANSFER: retryTrf,
  LOADING_STATEMENT: setStatementLoading,
  STATEMENT_AVAILABLE: setAvailalable,
  ACCOUNT_STATEMENT: getAcctStatementData
};
const initialState = {};

export default function TransfersReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
