import React, { useState, useEffect, useCallback } from "react";
// import { debounce } from 'lodash'
import { connect } from "react-redux";
import { getAccountName, getAccountStatement } from "../../../reducers/transfers";
import {
  Col,
  Container,
  Row,
  Form,
  Input,
  FormGroup,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Loader from "./Loader";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { getAccountDetails } from "../../../actions/postingAction";
import "../../../assets/js/scripts.js";
import { toggleModal } from "../../../actions/postingAction";
import { getTransactionList } from "../../../actions/statement.js";
import { CSVLink, CSVDownload } from "react-csv";
const BankStatement = (props) => {
  const { user } = window.localStorage;
  const { org_id: orgId } = JSON.parse(user);

  const [loading, setLoading] = useState(false);
  const [accountNumber, setAccountNumber] = useState(null)
  const [usingName, setUsingName] = useState(false)

  const [statementAvailable, setStatementAvailable] = useState(false)
  const [statementData, setStatementData] = useState([

  ])

  const formik = useFormik({
    initialValues: {
      useAccount: "",
      accountNumber: "",
      accountName: "",
      startDate: "",
      endDate: ""
    },
    validationSchema: Yup.object({
      useAccount: Yup.string().required("Checkbox value is required"),
      accountNumber: Yup.string().required("Account number is required"),
      accountName: Yup.string().required("Account name is required"),
      startDate: Yup.string(),
      endDate: Yup.string(),

    }),
    onSubmit: async (values) => {

      if(!isWithinThreeMonths(values.startDate, values.endDate)) {
        formik.setFieldError(
          'endDate', 'Only 3 month interval is allow.'
        )
        return;
      }

      await props.getAccountStatement({
         org_id: orgId, 
         nuban: values.useAccount === "NUBAN" ? true : false, 
         acct_number: values.accountNumber, 
         start_date: values.startDate, 
         end_date: values.endDate, 
         service_code: 'maxipro', 
         account_type: values.useAccount 
        })
    },
  });

  const handleTransactionRecord = (transactions) => {
    const csvData = [
      ["Date", "Narration", "Transaction No", "Value Date", "Withdrawal", "Lodgement", "Balance"],
    ];

    let currentBalance = 0;
    transactions.statements.forEach(eachTran => {
      currentBalance = eachTran.transactionType === 'debit' ? (currentBalance - eachTran.amount) : (currentBalance + eachTran.amount)
      csvData.push([
        eachTran.date,
        eachTran.naration,
        eachTran.transactionRef,
        eachTran.date,
        eachTran.transactionType === 'debit' ? eachTran.amount : 0,
        eachTran.transactionType === 'credit' ? eachTran.amount : 0,
        eachTran.balance
      ])
    })


    csvData.push([
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    ])

    csvData.push([
      '',
      '',
      '',
      '',
      '',
      'Total Balance',
      transactions.balance_record.finalBalance
    ])
    setStatementData(csvData)
    setStatementAvailable(true)
    setLoading(false)
  }

  const isWithinThreeMonths = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
  
    // Get the year and month difference between the two dates
    const yearDifference = d2.getFullYear() - d1.getFullYear();
    const monthDifference = d2.getMonth() - d1.getMonth();
  
    // Calculate total month difference
    const totalMonthDifference = (yearDifference * 12) + monthDifference;
  
    // Return true if the difference is 3 months or less, false otherwise
    return totalMonthDifference <= 3;
  }

  // fieldNameValue: nuban or internal
  const handleUseAccount = async (event, fieldNameValue) => {
    const checked = event.target.checked;

    if (checked === true) {

      formik.setFieldValue("useAccount", fieldNameValue, true);
      setLoading(true);
      const responseData = await getAccountDetails(
        accountNumber,
        orgId,
        false,
        true
      );
      const { getAccountName: { balance } } = responseData

      setLoading(false);
      if (fieldNameValue === 'NUBAN') {
        formik.setFieldValue("accountNumber", accountNumber, true);
      }
      if (fieldNameValue === 'Internal') {
        formik.setFieldValue("accountNumber", accountNumber, true);
      }
    } else {
      formik.setFieldValue("useAccount", "", true);
    }
  };


  const handleAccountDetails = async (event) => {
    formik.setFieldValue("accountNumber", event.target.value, true);
    if (event.target.value.length > 9) {
      setLoading(true);
      setAccountNumber(event.target.value)
      const responseData = await getAccountDetails(
        event.target.value,
        orgId,
        false,
        true
      );
      if (responseData.getAccountName.acct_name) {
        formik.setFieldValue(
          "accountName",
          responseData.getAccountName.acct_name,
          true
        );
        // formik.setFieldValue(
        //   "accountBalance",
        //   responseData.getAccountName.balance,
        //   true
        // );
      } else {
        setLoading(false);
        toast.error("Invalid account number");
      }
      setLoading(false);
    }
  };



  const toggleModal = () => {
    props.toggleModal();
  };

  useEffect(() => {
    if (props.statementAvailable) {
      handleTransactionRecord(props.statements)
    }
  }, [props.statementAvailable, props.statements])

  return (
    <Container>
      {loading | props.loading && <Loader />}
      <Row className="justify-content-center login-container align-items-center">

        <Col xs={12} md={12} lg={10}>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <div className="text-center mb-4">
                <h6 className="h3 font-weight-normal text-success">
                  Account Statement
                </h6>
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="accountNumber">
                <small className="font-weight-bold">Account Number</small>
              </Label>
              <Input
                id="accountNumber"
                name="accountNumber"
                className="form-control"
                onChange={handleAccountDetails}
                onBlur={formik.handleBlur}
                required
                type="text"
                value={formik.values.accountNumber}
              />
              {formik.touched.accountNumber && formik.errors.accountNumber ? (
                <div className="text-danger small">
                  {formik.errors.accountNumber}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={formik.values.useAccount === "Internal"}
                    id="useAccount"
                    name="useAccount"
                    onChange={(event) => handleUseAccount(event, "Internal")}
                    onBlur={formik.handleBlur}
                  />
                  <label class="form-check-label" for="useAccount">
                    <small>Use Internal Account</small>
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={formik.values.useAccount === "NUBAN"}
                    id="useAccount"
                    name="useAccount"
                    onChange={(event) => handleUseAccount(event, "NUBAN")}
                    onBlur={formik.handleBlur}
                  />
                  <label class="form-check-label" for="flexCheckChecked">
                    <small>Use NUBAN Account</small>
                  </label>
                </div>
              </div>


              {formik.touched.useAccount && formik.errors.useAccount ? (
                <div className="text-danger small">
                  {formik.errors.useAccount}
                </div>
              ) : null}
            </FormGroup>


            <FormGroup>
              <Label for="accountNumber">
                <small className="font-weight-bold">Account name</small>
              </Label>
              <Input
                id="accountname"
                name="accountname"
                className="form-control"
                onChange={handleAccountDetails}
                onBlur={formik.handleBlur}
                required
                type="text"
                value={formik.values.accountName}
                disabled
              />
              {formik.touched.accountName && formik.errors.accountName ? (
                <div className="text-danger small">
                  {formik.errors.accountName}
                </div>
              ) : null}
            </FormGroup>


            <div className="form-row">
              <div className="col-sm-6">
                <FormGroup>
                  <Label for="startDate">
                    <small className="font-weight-bold">Statement Start Date</small>
                  </Label>
                  <Input
                    id="startDate"
                    name="startDate"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.startDate}
                    required
                    type="date"
                  />
                  {formik.touched.startDate && formik.errors.startDate ? (
                    <div className="text-danger small">
                      {formik.errors.startDate}
                    </div>
                  ) : null}
                </FormGroup>
              </div>

              <div className="col-sm-6">
                <FormGroup>
                  <Label for="endDate">
                    <small className="font-weight-bold">Statement End Date</small>
                  </Label>
                  <Input
                    id="endDate"
                    name="endDate"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.endDate}
                    required
                    
                    type="date"
                  />
                  {formik.touched.endDate && formik.errors.endDate ? (
                    <div className="text-danger small">
                      {formik.errors.endDate}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
            </div>

            <FormGroup>
              <Button
                disabled={props.accounts.pageLoading}
                outline
                color="success"
                className="form-control bg-success text-white font-weight-bold text-uppercase"
                type="submit"
                value="Submit"
              >
                Generate Bank Statement
              </Button>
            </FormGroup>
          </Form>

          {statementAvailable && (
            <FormGroup>
              <div className="text-center mb-0 mt-5">
                <h6 className="font-weight-normal">
                  Bank Statement is available for download
                </h6>
              </div>


              <CSVLink filename='account_statement' data={statementData}>
                <FormGroup>
                  <Button
                    disabled={props.accounts.pageLoading}
                    outline
                    color="success"
                    className="form-control bg-success text-white font-weight-bold text-uppercase"
                    type="submit"
                    value="Submit"
                  >
                    Download
                  </Button>
                </FormGroup>

              </CSVLink>
            </FormGroup>
          )
          }

        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { accounts, posting, transfer } = state;
  return { accounts, posting, loading: transfer.statementLoading, statements: transfer.statements, statementAvailable: transfer.statementAvailable };
};

const mapDispatchToProps = {
  getAccountName,
  toggleModal,
  getAccountStatement
};

export default connect(mapStateToProps, mapDispatchToProps)(BankStatement);
