import { v4 as uuidv4 } from 'uuid';
const axios = require('axios');

const CORE_BANKING_URL = "https://newapp.jekalo.com/ussdtest/app_mobile.php"

export const  getTransactionList = async ({accountNumber, serviceCode = 'arcpay', handleSuccess, handleFailure}) => {
    const sessionId = uuidv4();
    const response = await axios.post(CORE_BANKING_URL, {
      sessionId,
      serviceCode,
      phoneNumber: accountNumber,
      text: 5,
      org_id: '221200',
    });
    const responseData = response.data;
    console.log(responseData);
    const transactions = [];
    if (responseData && responseData.data) {
      const transactionsRaw = responseData.data
        .split('\n')
        .filter((item) => item !== '');
      if (transactionsRaw && transactionsRaw.length > 0) {
        transactionsRaw.map((transaction, index) => {
          console.log(transaction)
          const eachItem = transaction.split(',');
          const newTransaction = {
            id: index,
            transactionRef: eachItem[3],
            date: eachItem[0],
            transactionType: eachItem[1],
            amount: eachItem[2],
            status: eachItem[4],
          };
          transactions.push(newTransaction);
        });
        handleSuccess(transactions)
      }
    }

    return transactions;
  }